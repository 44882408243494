import React, { useCallback } from 'react';
import { MdCheck, MdBlock } from 'react-icons/md';
import { InputRow } from 'forms/shared/Common';
import { HookedTextInput } from 'forms/shared/HookedInputs';
import { useAppDispatch, useAppSelector, useInpectionUserData } from '@/hooks';
import { loadVSNInfoForPremiumprocess } from '@/actions/data/inspectionVoucherActions';
import EnsLoadingIndicator from "forms/shared/loading/EnsLoadingIndicator";

const PremiumserviceVertragsinfo = () => {
  const disptach = useAppDispatch();
  const onLoadVSNInfo = useCallback(() => disptach(loadVSNInfoForPremiumprocess()), []);

  const vsnFound = useAppSelector((state) => state.inspectionVoucher.vsnFound);
  const vsnLoading = useAppSelector((state) => state.inspectionVoucher.vsnLoading);

  return (
    <>
      <InputRow inputSize={9} title={'Vertragsnummer'}>
        <HookedTextInput
          field={'vsn'}
          userDataHook={useInpectionUserData}
          placeholder={'Deine Vertragsnummer'}
          onBlur={onLoadVSNInfo}
        />
      </InputRow>
      <InputRow inputSize={9} title={'Nachname'}>
        <HookedTextInput
          field={'lastname'}
          userDataHook={useInpectionUserData}
          placeholder={'Dein Nachname'}
          onBlur={onLoadVSNInfo}
        />
      </InputRow>
      {vsnFound === null && !vsnLoading ? null : (
        <InputRow inputSize={9} title={'Vertragsdaten'}>
          <div style={{ display: 'flex', position: "relative" }}>
            {!vsnFound ? null : (
              <span>
                Deine Vertragsdaten wurden gefunden <MdCheck color={'green'} />
              </span>
            )}
            {vsnFound !== false ? null : (
              <span>
                Vertragsdaten wurden nicht gefunden <MdBlock color={'red'} />
              </span>
            )}
            {!vsnLoading ? null : (
              <>
                <span>&nbsp;&nbsp;</span>
                <EnsLoadingIndicator />
              </>
            )}
          </div>
        </InputRow>
      )}
      <div style={{ marginBottom: '4em' }}></div>
    </>
  );
};

export default PremiumserviceVertragsinfo;
