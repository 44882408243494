import React from 'react';
import ProgressContainer from 'processes/shared/ProgressContainer';
import PremiumserviceProcessOrder from 'processes/premiumserviceProcess/PremiumserviceProcessOrder';

const PremiumserviceProcess = () => {
  return (
    <ProgressContainer>
      <PremiumserviceProcessOrder />
    </ProgressContainer>
  );
};

export default PremiumserviceProcess;
