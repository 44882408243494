import React from 'react';
import VoucherSelectionPage from 'forms/InspectionVoucher/VoucherSelectionPage';
import PremiumserviceVertragsinfo from 'processes/premiumserviceProcess/PremiumserviceVertragsinfo';

const PremiumserviceProcessOrder = () => {
  return (
    <VoucherSelectionPage frameless={true} hideSubmit={false} noVouchers={true}>
      <PremiumserviceVertragsinfo />
    </VoucherSelectionPage>
  );
};

export default PremiumserviceProcessOrder;
