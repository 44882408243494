import { useEnsOptions } from '@/reducer/ensOptions';
import { Box } from '@mantine/core';
import React from 'react';

const ProgressContainer = (props: React.PropsWithChildren) => {
  const isBorderless = useEnsOptions().isBorderless;

  return (
    <Box
      style={{
        border: isBorderless ? 'initial' : '1px solid #dddddd',
        background: isBorderless ? 'white' : '#fcfcfc',
        padding: '0 0px 5px 0px',
      }}>
      {props.children}
    </Box>
  );
};

export default ProgressContainer;
